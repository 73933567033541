import {useState} from 'react';
import logo from './logo.png';
import headerImageSrc from './HeaderImage.jpg';
import headerImage2Src from './HeaderImage2.jpg';
import './App.css';
import styled, {css} from 'styled-components'
import aquariusImage from "./SignImages/Aquarius.png"
import ariesImage from "./SignImages/Aries.png";
import cancerImage from "./SignImages/Cancer.png";
import capricornImage from "./SignImages/Capricorn.png";
import geminiImage from "./SignImages/Gemini.png";
import leoImage from "./SignImages/Leo.png";
import libraImage from "./SignImages/Libra.png";
import piscesImage from "./SignImages/Pisces.png";
import sagittariusImage from "./SignImages/Sagittarius.png";
import scorpioImage from "./SignImages/Scorpio.png";
import taurusImage from "./SignImages/Taurus.png";
import virgoImage from "./SignImages/Virgo.png";

enum Zodiac {
  Capricorn = "Capricorn",
  Aquarius = "Aquarius",
  Pisces = "Pisces",
  Aries = "Aries",
  Taurus = "Taurus",
  Gemini = "Gemini",
  Cancer = "Cancer",
  Leo = "Leo",
  Virgo = "Virgo",
  Libra = "Libra",
  Scorpio = "Scorpio",
  Sagittarius = "Sagittarius"
}

function App() {

  var [yourBirthday, setYourBirthday] = useState(null);
  var [partnersBirthday, setPartnersBirthday] = useState(null);
  var [yourZodiac, setYourZodiac] = useState<null | Zodiac>(null);
  var [partnersZodiac, setPartnersZodiac] = useState<null | Zodiac>(null);

  const dateChanged = (event: any, reactHandler: any) => {
    console.log("Date changed ", event.target.value);
    reactHandler(event.target.value);
  }

  const timeChanged = (event: any) => {
    console.log("Time changed: ", event.target.value);
  }

  const formSubmitted = (event: any) => {
    console.log("Form submitted");
    console.log(yourBirthday, partnersBirthday);
    if (!yourBirthday || !partnersBirthday) {
      return;
    }
    const yourLocalZodiac = getZodiacFromDateString(yourBirthday);
    const partnersLocalZodiac = getZodiacFromDateString(partnersBirthday);
    console.log(yourLocalZodiac, partnersLocalZodiac);
    setYourZodiac(yourLocalZodiac);
    setPartnersZodiac(partnersLocalZodiac);
  }

  const getZodiacFromDateString = (dateString: string): Zodiac => {
    const components = dateString.split('-');
    const month = parseInt(components[1], 10);
    const day = parseInt(components[2], 10);
    console.log("month: ", month, "day: ", day);
    switch (month) {
      case 1: // Jan
        return day <= 19 ? Zodiac.Capricorn : Zodiac.Aquarius;
      case 2: // Feb
        return day <= 18 ? Zodiac.Aquarius : Zodiac.Pisces;
      case 3: // Mar
        return day <= 20 ? Zodiac.Pisces : Zodiac.Aries;
      case 4: // Apr
        return day <= 19 ? Zodiac.Aries : Zodiac.Taurus;
      case 5: // May
        return day <= 20 ? Zodiac.Taurus : Zodiac.Gemini;
      case 6: // June
        return day <= 20 ? Zodiac.Gemini : Zodiac.Cancer;
      case 7: // July
        return day <= 22 ? Zodiac.Cancer : Zodiac.Leo;
      case 8: // August
        return day <= 22 ? Zodiac.Leo : Zodiac.Virgo;
      case 9: // Sept
        return day <= 22 ? Zodiac.Virgo : Zodiac.Libra;
      case 10: // Oct
        return day <= 22 ? Zodiac.Libra : Zodiac.Scorpio;
      case 11: // Nov
        return day <= 21 ? Zodiac.Scorpio : Zodiac.Sagittarius;
      case 12: // Dec
        return day <= 21 ? Zodiac.Sagittarius : Zodiac.Capricorn;
      default:
        return Zodiac.Sagittarius;
    }
  };


  const infoInput = (titleText: string, reactHandler: any) => {
    return <InfoInput>
    <InputSubhead>{titleText}</InputSubhead>
    <DateInput type="date" onChange={(e) => dateChanged(e, reactHandler)} />
    <br/>
    <BirthTime>Birth Time (optional)</BirthTime>
    <TimeInput type="time" onChange={timeChanged} />
  </InfoInput>
  }

  const submissionForm = () => {
    return <div>
    {infoInput("Your Birthday", setYourBirthday)}
    {infoInput("Partner's Birthday", setPartnersBirthday)}
    <SubmitButton onClick={formSubmitted} 
      yourBirthday={yourBirthday} partnersBirthday={partnersBirthday}
      disabled={!yourBirthday || !partnersBirthday}>Submit</SubmitButton>
    </div>
  }

  const resultsDiv = () => {
    return <ResultsDiv>
        <h3>Your Zodiac compatibility is...</h3> 
        <h1>Extremely Compatible!</h1>
        <FlexDiv>
          {!!yourZodiac && <ZodiacImage src={zodiacSource(yourZodiac)} /> }
          {!!partnersZodiac && <ZodiacImage src={zodiacSource(partnersZodiac)} />}
        </FlexDiv>
          <br />
        <FlexDiv>
          {!!yourZodiac && <ZodiacLabel>{yourZodiac.valueOf()}</ZodiacLabel>} 
          {!!partnersZodiac && <ZodiacLabel>{partnersZodiac.valueOf()}</ZodiacLabel>}
        </FlexDiv>
        <p>Wow! You and your partner's natal horoscopes are <i>EXTREMELY</i> compatible. 
        Regardless of the differences between {yourZodiac?.valueOf()} and {partnersZodiac?.valueOf()}, both of your <b>rising signs</b> and <b>moon signs</b> result in a union that is incredibly well-matched.
        This is a combination worth exploring!</p>
        <p>{yourZodiac?.valueOf()} and {partnersZodiac?.valueOf()} are a great combination because the two will sustain each other’s energy. They have the ability to forge a bond based on mutual respect and understanding.
        No partnership is without conflict, but this unique combination is sure to outlast them all.</p>
        <p>In addition, a host of other factors such as longevity of the individuals, character (sexual), widowhood, poverty, progeny, body status, radical strengths and indications, planetary nature and afflictions, currency of major and minor periods, Marakaas (death inflicting planets), placement of Mars in their nativity (Mangal Dosha), time of query, omen (Shagun), antidotes, propitiations, auspicious time for marriage etc. should be examined to arrive at a suitable union of the couple.</p>
    </ResultsDiv>
  }

  const variableBody = () => {
    if (!!yourZodiac && !!partnersZodiac) {
      return resultsDiv();
    } else {
      return submissionForm();
    }
  }

  const zodiacSource = (zodiac: Zodiac): string => {
    switch (zodiac) {
      case Zodiac.Aquarius:
        return aquariusImage;
      case Zodiac.Aries:
        return ariesImage;
      case Zodiac.Cancer:
        return cancerImage;
      case Zodiac.Capricorn:
        return capricornImage;
      case Zodiac.Gemini:
        return geminiImage;
      case Zodiac.Leo:
        return leoImage;
      case Zodiac.Libra:
        return libraImage;
      case Zodiac.Pisces:
        return piscesImage;
      case Zodiac.Sagittarius:
        return sagittariusImage;
      case Zodiac.Scorpio:
        return scorpioImage;
      case Zodiac.Taurus:
        return taurusImage;
      case Zodiac.Virgo:
        return virgoImage;
    }
  }

  return (
    <div className="App">
      <MenuBar>
        <MenuImage src={logo} /><MenuTitle>AstroCompatible</MenuTitle>
        <Line />
      </MenuBar>
      <HeaderDiv>
        <HeaderText>Compatibility Check</HeaderText>
        <HeroImage src={headerImageSrc} />
        <HeroImage2 src={headerImage2Src} />
        <Subhead>Discover your Zodiac compatibility</Subhead>
        <BlockText>Astrological compatibility (<a href="">synastry</a>) is the branch of astrology that studies relationships by comparing natal horoscopes. A natal horoscope is a chart or map of the angles of the planets in the Solar System and their positions in the zodiac at the exact time of a person's birth. These angles represent the positive and negative relationships between the planets. These relationships describe the relationship between the two people under consideration.</BlockText>
      </HeaderDiv>
      { variableBody() }
    </div>
  );
}

export default App;

const BlueColor = css`
  color: rgb(29, 19, 51);
`;

const MenuBar = styled.div`
  margin-top: 1%;
  ${BlueColor}
`;

const MenuImage = styled.img`
  margin-left: 1%;
  margin-right: 2em;
  display: inline;
  width: 25px;
  height: 25px;
`;

const MenuTitle = styled.h1`
  display: inline;
`;

const Line = styled.div`
  background-color: rgb(29, 19, 51, 0.3);
  height: 1px;
  width: 100%;
  margin-top: 1%;
`;


const HeaderDiv = styled.div`
  ${BlueColor}
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 2%;
`;

const HeroImage = styled.img`
  height: 300px;
  display: block;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 100px;
`;

const HeroImage2 = styled.img`
  height: 300px;
  display: block;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 500px;
`;

const HeaderText = styled.h2`
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`;

const Subhead = styled.h3`
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`;

const BlockText = styled.p`
  margin-block-start: 0.1em;
  margin-block-end: 0;
`;

// background-color: rgb(7, 0, 170);;

const InfoInput = styled.div`
  background-color: rgb(53, 74, 239);
  border-radius: 20px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  padding: 1%;
  padding-bottom: 2%;
  color: white;
`;

const InputSubhead = styled.h3`
  margin-bottom: 0.4em;
  margin-block-start: 0.3em;
`;

const BirthTime = styled.p`
  margin-bottom: 0.2em;
`;

const InputStyle = css`
  color: rgb(29, 19, 51);
  border-radius: 10px;
  border: 2px;
  padding: 0.8rem;
  font-size: 20px;
`;

// padding: 0.5rem 0;
//   margin: 0.5rem 1rem;
// width: 11rem;
// border: 2px solid white;

const DateInput = styled.input`
  ${InputStyle}
  margin-right: 1%;
`;

const TimeInput = styled.input`
  ${InputStyle}
  margin-top: 1%;
`;

const SubmitButton = styled.button<any>`
  color: white;
  background-color: ${props => {
    return !!props.yourBirthday && !!props.partnersBirthday ? "rgb(53, 74, 239)" : "grey"
  }};
  border-radius: 10px;
  border: 0;
  text-align: center;
  display: block; 
  margin: 3% auto;
  padding: 10px 20px 10px 20px;
  font-size: 20px;
`;

const ResultsDiv = styled.div`
  margin-left: 5%;

  p {
    margin-right: 2%;
  }
`;

const ZodiacImage = styled.img`
  width: min(40%, 300px);
  display: inline-block;
  margin: auto;
`;

const ZodiacLabel = styled.h3`
  display: inline-block;
  margin: auto;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${BlueColor}
`;

